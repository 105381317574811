exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"variables.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px"
};