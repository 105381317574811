var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.formInline, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "输入报告号码", prop: "reportno" } },
            [
              _c("el-input", {
                attrs: { placeholder: "1124" },
                model: {
                  value: _vm.formInline.reportno,
                  callback: function($$v) {
                    _vm.$set(_vm.formInline, "reportno", $$v)
                  },
                  expression: "formInline.reportno"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: { data: _vm.list, size: "mini", border: "", fit: "" },
          on: { "cell-dblclick": _vm.bccelldblclick }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              width: "55",
              type: "index",
              index: _vm.indexMethod
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Report_No", width: "200", prop: "report_no" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.report_no) +
                        "\n          "
                    ),
                    _c("i", {
                      staticClass: "el-icon-platform-eleme",
                      on: {
                        click: function($event) {
                          return _vm.openPdf(scope)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "来源编号", width: "250", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.report_no_real))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "报告状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": true, "inactive-value": false },
                      model: {
                        value: scope.row.status_real,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status_real", $$v)
                        },
                        expression: "scope.row.status_real"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("report-table", {
        ref: "ReportTable",
        attrs: { "show-dialog1": _vm.showDialog1, list: _vm.list1 },
        on: {
          "update:showDialog1": function($event) {
            _vm.showDialog1 = $event
          },
          "update:show-dialog1": function($event) {
            _vm.showDialog1 = $event
          }
        }
      }),
      _vm._v(" "),
      _c("h2", [_vm._v("已上传总表")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: { data: _vm.uploaded_list, size: "mini", border: "", fit: "" },
          on: { "cell-dblclick": _vm.bccelldblclick }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              width: "75",
              type: "index",
              index: _vm.indexMethod
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "75", prop: "id" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Report_No", width: "140", prop: "report_no" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "报告类型",
              width: "100",
              align: "center",
              prop: "cert_type"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "报告链接",
              width: "700",
              align: "center",
              prop: "file_url",
              "show-overflow-tooltip": ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "返回字符串", prop: "res_json" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.formInline1.page,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _vm.formInline1.page_size,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.formInline1.total
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }