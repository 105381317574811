import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, setTimeStamp } from '@/utils/auth'
import { resetRouter } from '@/router'

const state = {
  token: getToken(), // 设置token初始化, 放到缓存中
  userInfo: {}
}

// 同步修改状态
const mutations = {
  // 设置token
  setToken(state, token) {
    state.token = token
    setToken(token) // // vuex和 缓存数据的同步
  },
  // 删除缓存
  removeToken(state) {
    state.token = null
    removeToken() // 先清除 vuex  再清除缓存 vuex和 缓存数据的同步
  },

  // 设置用户信息
  setUserInfo(state, result) {
    state.userInfo = result
  },
  removeUserInfo(state) {
    state.userInfo = {}
  },
  SET_NAME: (state, name) => {
    state.name = name
    setToken(name)
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
    setToken(avatar)
  }
}

// 异常修改方法
const actions = {
  // user login
  async login(context, data1) {
    // 表示登录接口调用成功 也就是意味着你的用户名和密码是正确的
    // 现在有用户token
    // actions 修改state 必须通过mutations
    const result = await login(data1)
    console.log(result)
    context.commit('setToken', result.token)
    // 写入最新token时间戳
    setTimeStamp()
  },

  // get user info
  async getInfo(context) {
    const baseResult = await getInfo(state.token) // 用户的基本资料
    console.log(baseResult)

    context.commit('setUserInfo', baseResult)

    return baseResult
  },

  // user logout
  logout({ commit, state }) {
    commit('removeToken')
    commit('removeUserInfo')
    removeToken() // must remove  token  first
    resetRouter()
    // commit('RESET_STATE')
    // return new Promise((resolve, reject) => {
    //   logout(state.token).then(() => {
    //     removeToken() // must remove  token  first
    //     resetRouter()
    //     commit('RESET_STATE')
    //     resolve()
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

