import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getTimeStamp } from '@/utils/auth'
import router from '@/router'

const TimeOut = 604800 // 定义超时时间 秒级单位
// 导出一个axios的实例  而且这个实例要有请求拦截器 响应拦截器

// create an axios instance
const service1 = axios.create({
  baseURL: '/cert', // url = base url + request url
  // baseURL: '/api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service1.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      if (IsCheckTimeOut()) {
        // token没用了已经超时 退出
        store.dispatch('user/logout')
        // 跳转到登录页
        router.push('user/login')
        // Message.error('token超时了')
        return Promise.reject(new Error('token超时了'))
      }
      // 如果token 存在 注入
      config.headers.Authorization = `Bearer ${store.getters.token}`
    }
    return config // 必须返回config 才能生效
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 是否超时
// 超时逻辑  (当前时间  - 缓存中的时间) 是否大于 时间差
function IsCheckTimeOut() {
  var currentTime = Date.now()// 当前时间戳
  var timeStamp = getTimeStamp()// 缓存时间戳
  return (currentTime - timeStamp) / 1000 > TimeOut
}

// response interceptor
service1.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    console.log('err' + error) // for debug
    // 退出登录
    await store.dispatch('user/logout')
    router.push({
      path: '/login',
      query: { redirect: router.currentRoute.fullPath }
    })

    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service1
