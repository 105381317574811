import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/login/login',
    method: 'post',
    data

  })
}
export function upload_list(data) {
  return request({
    url: '/report/uploadList',
    method: 'post',
    data

  })
}

export function getInfo(token) {
  return request({
    url: '/Article/index',
    method: 'get',
    params: { token }
  })
}

// 获取文件列件
export function getFile(params) {
  return request({
    url: '/report/fileList',
    method: 'get',
    params
  })
}

// 通过旧系统号码查询
export function getOldSystemNo(params) {
  return request({
    url: '/report/oldfileList',
    method: 'get',
    params
  })
}

// 获取报告详情
export function getReprtDetail(params) {
  return request({
    url: '/report/index',
    method: 'get',
    params
  })
}
// 获取手动报告详情
export function getManualReprtDetail(params) {
  return request({
    url: '/report/manual',
    method: 'get',
    params
  })
}

// 获取待上传列表
export function getNeedUploadList(data) {
  return request({
    url: '/login/needUpload',
    method: 'post',
    data
  })
}

// 查询报告号码是否已存在数据据
export function getReportNo(params) {
  return request({
    url: '/report/reportNo',
    method: 'get',
    params
  })
}
// 查询报告号码是否已存在数据据
export function getReportAllNo() {
  return request({
    url: '/report/getReportAllNo',
    method: 'get'
  })
}

// 分页查询所有报告
export function getReportList(params) {
  return request({
    url: '/report/getReportList',
    method: 'get',
    params
  })
}
