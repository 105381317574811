import router from '@/router'
import store from '@/store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar 引入进度条
import 'nprogress/nprogress.css' // progress bar style 引入进度条样式
// import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

/**
 * 前置守卫
 */
router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // // set page title
  // document.title = getPageTitle(to.meta.title)

  // // determine whether the user has logged in
  // const hasToken = getToken()

  if (store.getters.token) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // 判断是否获取过资料
      if (!store.getters.name) {
        await store.dispatch('user/getInfo')
      }

      next()
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

/**
 * 前置守卫
 */
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
