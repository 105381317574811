<template>
  <el-dialog
    :visible="showDialog1"
    :title="title"
    width="80%"
    @close="handleCancel1"
  >

    <el-table
      :data="list"
      border
      fit

      @cell-dblclick="bccelldblclick"
    >
      <el-table-column align="center" label="ID" width="55">
        <template slot-scope="scope">
          {{ scope.$index }}
        </template>
      </el-table-column>
      <el-table-column label="Report_No" width="200" prop="report_no" />
      <el-table-column label="文件名称" width="250" align="center" prop="org_name" />
      <el-table-column
        label="PDF报告外网链接"
        show-overflow-tooltip
        width="300"
        align="center"
        prop="path"
      />

      <el-table-column label="报告状态" prop="status">

        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" />
        </template>

      </el-table-column>

      <el-table-column
        align="center"
        prop="created_at"
        label="Display_time"
        width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ scope.row.add_time }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pdd-upload ref="PddUpload" :report-no="ReportNo" :file-url="FileUrl" :file-no="FileNo" :show-dialog.sync="showDialog" />
  </el-dialog>

</template>

<script>
// import { getFile } from '@/api/user'
import PddUpload from './pdd_upload.vue'

export default {
  name: 'ReportTable',
  components: { PddUpload },
  props: {
    showDialog1: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '上传列表'
    },
    reportNo: {
      type: String,
      default: null
    },
    fileNo: {
      type: String,
      default: ''
    },
    fileUrl: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: null
    }
  },
  data() {
    return {

      ReportNo: null,
      FileUrl: '',
      FileNo: '',
      url: 'https://report.annrayonline.com',
      listLoading: true,
      showDialog: false,
      formInline: {
        reportno: ''
      },
      // showDialog: false,
      rules: {
        reportno: [{ required: true, message: '报告号码不能为空', trigger: 'blur' }]
      }

    }
  },
  created() {
    // if (this.formInline.reportno !== '1124') {
    //   this.onSubmit()
    // }
  },
  methods: {

    uploadPage() {
      location.reload()
    },

    // 关闭弹窗
    handleCancel() {
      // 修改父组件的值
    //   this.showDialog = false
      this.$emit('update:showDialog', false)
      this.$refs.form.resetFields()
      // this.$parent.uploadPage()
    },
    handleCancel1() {
      // 修改父组件的值
    //   this.showDialog = false
      this.$emit('update:showDialog1', false)
      // this.$refs.form.resetFields()
      // this.$parent.uploadPage()
    },

    bccelldblclick(row, column, cell, event) {
      this.$nextTick(() => {
        this.ReportNo = row.report_no
        this.FileUrl = this.url + row.path.substr(1)
        this.FileNo = row.org_name.split('_')[0]

        // this.$refs.PddUpload 等同于子组件的this
      })
      this.showDialog = true
      setTimeout(() => {
        this.$refs.PddUpload.getList()
      }, 3000)
    },
    openPdf(scope) {
      window.open(this.url + scope.row.path.substr(1))
    }

  }
}
</script>
