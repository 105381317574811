var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      !_vm.sidebar.hide
        ? _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: { "is-active": _vm.sidebar.opened },
            on: { toggleClick: _vm.toggleSideBar }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }