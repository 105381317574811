/**
api接口的统一出口
*/

// import example from './example'
'/cert/institution/upload_file'

import request1 from '@/utils/request1'

/**
 * 上传报告
 * @param {*} data
 * @returns
 */
export function uploadFiles(data) {
  return request1({
    url: '/institution/upload_file',
    method: 'post',
    data

  })
}
