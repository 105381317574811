var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.showDialog, title: _vm.title, width: "80%" },
      on: { close: _vm.handleCancel }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.data_list[0],
            "label-width": "170px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "实验室ID", prop: "institution_id" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.data_list[0].institution_id,
                          callback: function($$v) {
                            _vm.$set(_vm.data_list[0], "institution_id", $$v)
                          },
                          expression: "data_list[0].institution_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传报告类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.data_list[0].report_type,
                            callback: function($$v) {
                              _vm.$set(_vm.data_list[0], "report_type", $$v)
                            },
                            expression: "data_list[0].report_type"
                          }
                        },
                        _vm._l(_vm.report_type_option, function(item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.label, value: item.key }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "资质类型" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.data_list[0].cert_type,
                          callback: function($$v) {
                            _vm.$set(_vm.data_list[0], "cert_type", $$v)
                          },
                          expression: "data_list[0].cert_type"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "外部唯⼀编号(必传)", prop: "file_no" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "外部唯⼀编号(必传)" },
                        model: {
                          value: _vm.data_list[0].file_no,
                          callback: function($$v) {
                            _vm.$set(_vm.data_list[0], "file_no", $$v)
                          },
                          expression: "data_list[0].file_no"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "报告⽣成⽇期(必传)",
                        "label-width": "170px",
                        prop: "check_date"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: { type: "date", placeholder: "选择日期" },
                        model: {
                          value: _vm.data_list[0].check_info.check_date,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.data_list[0].check_info,
                              "check_date",
                              $$v
                            )
                          },
                          expression: "data_list[0].check_info.check_date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "检测产品名称(必传)",
                        prop: "check_product_name"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.data_list[0].check_info.check_product_name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.data_list[0].check_info,
                              "check_product_name",
                              $$v
                            )
                          },
                          expression:
                            "data_list[0].check_info.check_product_name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "检测产品型号(必传)",
                        prop: "check_product_model"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value:
                            _vm.data_list[0].check_info.check_product_model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.data_list[0].check_info,
                              "check_product_model",
                              $$v
                            )
                          },
                          expression:
                            "data_list[0].check_info.check_product_model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "检测产品⼚商(必传)",
                        prop: "check_product_manufacture"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value:
                            _vm.data_list[0].check_info
                              .check_product_manufacture,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.data_list[0].check_info,
                              "check_product_manufacture",
                              $$v
                            )
                          },
                          expression:
                            "data_list[0].check_info.check_product_manufacture"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "前⼀版本⽂件编号" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.data_list[0].pre_file_no,
                          callback: function($$v) {
                            _vm.$set(_vm.data_list[0], "pre_file_no", $$v)
                          },
                          expression: "data_list[0].pre_file_no"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "第⼀版本⽂件编号" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.data_list[0].root_file_no,
                          callback: function($$v) {
                            _vm.$set(_vm.data_list[0], "root_file_no", $$v)
                          },
                          expression: "data_list[0].root_file_no"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "上传⽂件⽅式： 0:外⽹可下载url转存(必传)",
                "label-width": "300px",
                prop: "upload_type"
              }
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.data_list[0].upload_type,
                  callback: function($$v) {
                    _vm.$set(_vm.data_list[0], "upload_type", $$v)
                  },
                  expression: "data_list[0].upload_type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否为测试数据 true: 测试数据，false 正式数据 （必传）",
                "label-width": "400px",
                prop: "is_test"
              }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.data_list[0].is_test,
                  callback: function($$v) {
                    _vm.$set(_vm.data_list[0], "is_test", $$v)
                  },
                  expression: "data_list[0].is_test"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "实验室英文名", prop: "institution_name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data_list[0].institution_name,
                  callback: function($$v) {
                    _vm.$set(_vm.data_list[0], "institution_name", $$v)
                  },
                  expression: "data_list[0].institution_name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "文件url", prop: "file_url" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data_list[0].file_url,
                  callback: function($$v) {
                    _vm.$set(_vm.data_list[0], "file_url", $$v)
                  },
                  expression: "data_list[0].file_url"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("立即上传")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }