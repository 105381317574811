var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "top-nav" },
    [
      _c("div", { staticClass: "log" }, [_vm._v("安瑞后台管理系统")]),
      _vm._v(" "),
      _c(
        "el-menu",
        {
          attrs: {
            "active-text-color": _vm.variables.menuActiveText,
            "default-active": _vm.activeMenu,
            mode: "horizontal"
          },
          on: { select: _vm.handleSelect }
        },
        _vm._l(_vm.routes, function(item) {
          return _c(
            "div",
            { key: item.path, staticClass: "nav-item" },
            [
              _c(
                "app-link",
                { attrs: { to: _vm.resolvePath(item) } },
                [
                  !item.hidden
                    ? _c("el-menu-item", { attrs: { index: item.path } }, [
                        _vm._v(
                          _vm._s(
                            item.meta
                              ? item.meta.title
                              : item.children[0].meta.title
                          )
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.avatar
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar }
                    })
                  : _c("span", { staticClass: "username" }, [
                      _vm._v(_vm._s(_vm.name.charAt(0)))
                    ]),
                _vm._v(" "),
                _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-setting" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v("Home")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://github.com/PanJiaChen/vue-admin-template/",
                        target: "_blank"
                      }
                    },
                    [_c("el-dropdown-item", [_vm._v("Github")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://panjiachen.github.io/vue-element-admin-site/#/",
                        target: "_blank"
                      }
                    },
                    [_c("el-dropdown-item", [_vm._v("Docs")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("Log Out")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }