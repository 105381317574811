var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.formInline, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "输入报告号码", prop: "reportno" } },
            [
              _c("el-input", {
                attrs: { placeholder: "1124" },
                model: {
                  value: _vm.formInline.reportno,
                  callback: function($$v) {
                    _vm.$set(_vm.formInline, "reportno", $$v)
                  },
                  expression: "formInline.reportno"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: { data: _vm.list, border: "", fit: "" },
          on: { "cell-dblclick": _vm.bccelldblclick }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\r\n          " + _vm._s(scope.$index) + "\r\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Report_No", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\r\n          " +
                        _vm._s(scope.row.title) +
                        "\r\n          "
                    ),
                    _c("i", {
                      staticClass: "el-icon-platform-eleme",
                      on: {
                        click: function($event) {
                          return _vm.openPdf(scope)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "文件名称", width: "250", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.org_name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "PDF报告外网链接",
              "show-overflow-tooltip": "",
              width: "300",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\r\n          " + _vm._s(scope.row.path) + "\r\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "报告状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "Display_time",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.add_time))])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pdd-upload", {
        ref: "PddUpload",
        attrs: {
          "report-no": _vm.ReportNo,
          "file-url": _vm.FileUrl,
          "file-no": _vm.FileNo,
          "show-dialog": _vm.showDialog
        },
        on: {
          "update:showDialog": function($event) {
            _vm.showDialog = $event
          },
          "update:show-dialog": function($event) {
            _vm.showDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }