<template>
  <div class="app-container">
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      :rules="rules"
      class="demo-form-inline"
    >
      <el-form-item label="输入报告号码" prop="reportno">
        <el-input v-model="formInline.reportno" placeholder="1124" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button type="success" @click="onSubmitOld">旧系统号码查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="list"

      border
      fit

      @cell-dblclick="bccelldblclick"
    >
      <el-table-column align="center" label="ID" width="55">
        <template slot-scope="scope">
          {{ scope.$index }}
        </template>
      </el-table-column>
      <el-table-column label="Report_No" width="200">

        <template slot-scope="scope">
          {{ scope.row.title }}
          <i class="el-icon-platform-eleme" @click="openPdf(scope)" />
        </template>
      </el-table-column>
      <el-table-column label="文件名称" width="250" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.org_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="PDF报告外网链接"
        show-overflow-tooltip
        width="300"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.path }}
        </template>
      </el-table-column>

      <el-table-column label="报告状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="created_at"
        label="Display_time"
        width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ scope.row.add_time }}</span>
        </template>
      </el-table-column>
    </el-table>

    <pdd-upload ref="PddUpload" :report-no="ReportNo" :file-url="FileUrl" :file-no="FileNo" :show-dialog.sync="showDialog" />
  </div>
</template>

<script>
import { getFile, getOldSystemNo } from '@/api/user'
import PddUpload from './component/pdd_upload.vue'

export default {
  components: { PddUpload },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      list: null,
      ReportNo: '',
      FileUrl: '',
      FileNo: '',
      url: 'https://report.annrayonline.com',
      listLoading: true,
      formInline: {
        reportno: ''
      },
      showDialog: false,
      rules: {
        reportno: [{ required: true, message: '报告号码不能为空', trigger: 'blur' }]
      }

    }
  },
  created() {
    // if (this.formInline.reportno !== '1124') {
    //   this.onSubmit()
    // }
  },
  methods: {
    formatStatus(row, column, cellValue, index) {
      console.log(cellValue)
      return cellValue === 1
    },
    fetchData() {
      // this.listLoading = true
      getFile(this.formInline).then(response => {
        this.list = response.data
        // this.listLoading = false
      })
    },
    async onSubmit() {
      this.$refs.form.validate(async(isOK) => {
        if (isOK) {
          const list = await getFile(this.formInline)

          this.list = list
          this.listLoading = false
        }
      })
    },
    async onSubmitOld() {
      this.$refs.form.validate(async(isOK) => {
        if (isOK) {
          const list = await getOldSystemNo(this.formInline)

          this.list = list
          this.listLoading = false
        }
      })
    },

    uploadPage() {
      location.reload()
    },

    bccelldblclick(row, column, cell, event) {
      this.$nextTick(() => {
        this.ReportNo = row.report_no + ''
        this.FileUrl = this.url + row.path.substr(1)
        this.FileNo = row.org_name.split('_')[0]

        // this.$refs.PddUpload 等同于子组件的this
      })
      this.showDialog = true
      setTimeout(() => {
        this.$refs.PddUpload.getList()
      }, 3000)
    },
    openPdf(scope) {
      window.open(this.url + scope.row.path.substr(1))
    }

  }
}
</script>
