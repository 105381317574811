<template>
  <el-dialog
    :visible="showDialog"
    :title="title"
    width="80%"
    @close="handleCancel"
  >
    <el-form ref="form" :rules="rules" :model="data_list[0]" label-width="170px">

      <el-row>
        <el-col :span="12">
          <el-form-item label="实验室ID" prop="institution_id">
            <el-input v-model="data_list[0].institution_id" />
          </el-form-item>
          <el-form-item label="上传报告类型">
            <el-select
              v-model="data_list[0].report_type"
            >
              <el-option
                v-for="item in report_type_option"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              />

            </el-select>
          </el-form-item>
          <el-form-item label="资质类型">
            <el-input v-model="data_list[0].cert_type" />
          </el-form-item>
          <el-form-item label="外部唯⼀编号(必传)" prop="file_no">
            <el-input v-model="data_list[0].file_no" placeholder="外部唯⼀编号(必传)" />
          </el-form-item>
          <el-form-item label="报告⽣成⽇期(必传)" label-width="170px" prop="check_date">

            <el-date-picker
              v-model="data_list[0].check_info.check_date"
              type="date"
              placeholder="选择日期"
              style="width: 100%;"
            />

          </el-form-item>

        </el-col>
        <el-col :span="12">

          <el-form-item label="检测产品名称(必传)" prop="check_product_name">
            <el-input v-model="data_list[0].check_info.check_product_name" />
          </el-form-item>
          <el-form-item label="检测产品型号(必传)" prop="check_product_model">
            <el-input v-model="data_list[0].check_info.check_product_model" />
          </el-form-item>
          <el-form-item label="检测产品⼚商(必传)" prop="check_product_manufacture">
            <el-input v-model="data_list[0].check_info.check_product_manufacture" />
          </el-form-item>
          <el-form-item label="前⼀版本⽂件编号">
            <el-input v-model="data_list[0].pre_file_no" />
          </el-form-item>

          <el-form-item label="第⼀版本⽂件编号">
            <el-input v-model="data_list[0].root_file_no" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="上传⽂件⽅式： 0:外⽹可下载url转存(必传)" label-width="300px" prop="upload_type">
        <el-input v-model="data_list[0].upload_type" />
      </el-form-item>

      <el-form-item label="是否为测试数据 true: 测试数据，false 正式数据 （必传）" label-width="400px" prop="is_test">
        <el-switch v-model="data_list[0].is_test" />
      </el-form-item>

      <el-form-item label="实验室英文名" prop="institution_name">
        <el-input v-model="data_list[0].institution_name" />
      </el-form-item>
      <el-form-item label="文件url" prop="file_url">
        <el-input v-model="data_list[0].file_url" />
      </el-form-item>

      <el-form-item>
        <el-row type="flex" justify="center">
          <el-button type="primary" @click="onSubmit">立即上传</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </el-row>

      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { getReprtDetail, upload_list, getManualReprtDetail } from '@/api/user'
import { uploadFiles } from '@/api/report'

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '上传'
    },
    reportNo: {
      type: String,
      default: null
    },
    fileNo: {
      type: String,
      default: ''
    },
    fileUrl: {
      type: String,
      default: ''
    }

  },

  data() {
    return {

      report_data: {},
      file_json: {},
      // post上传的url
      // https://kci.vgunxpkt.com/cert/institution/upload_file' // 机构上传资质报告接⼝url
      report_type_option: [
        { label: '0.证书', key: 0 },
        { label: '1.报告', key: 1 }
      ],
      'data_list': [
        {
          'institution_id': '3016', // 分配的检测机构id(必传)，
          'institution_name': 'Annray Testing and Certification (Guangdong) Co., Ltd.', // 检测机构英⽂名(必传)
          'file_url': '', // ⽂件url(必传)
          'report_type': 1, // 上报⽂件类型：0：证书 1：报告(必传)
          'cert_type': 'CPC', // 资质类型: 如CPC，CE，使⽤下⽅cert_type枚举（report_type 为0时必传）
          'file_no': '', // 当前⽂件外部唯⼀编号(必传),
          'pre_file_no': '', // 更新场景前⼀版本⽂件编号
          'root_file_no': '', // 更新场景第⼀版本⽂件编号
          'upload_type': 0, // 上传⽂件⽅式： 0：外⽹可下载url转存(必传)
          'is_test': false, // 是否为测试数据 true: 测试数据，false 正式数据 （必传）
          'check_info': {
            'check_product_name': '', // 检测产品名称(必传)，
            'check_product_model': '', // 检测产品型号(必传),
            'check_product_manufacture': '', // 检测产品⼚商(必传),
            'check_date': '2023-11-01' // 报告⽣成⽇期,格式： yyyy-MM-dd(必传)
          }
        }
      ],
      rules: {

        institution_id: [
          { required: true, message: '机构ID不能为空', trigger: 'blur' }
        ], // 分配的检测机构id(必传)，
        institution_name: [
          { required: true, message: '机构英文名不能为空', trigger: 'blur' }
        ], // 检测机构英⽂名(必传)
        file_url: [{ required: true, message: '文件url不能为空', trigger: 'blur' }], // ⽂件url(必传)
        report_type: [{ required: true, message: '上报⽂件类型不能为空', trigger: 'blur' }], // 上报⽂件类型：0：证书 1：报告(必传)
        cert_type: [{ required: true, message: '资质类型不能为空', trigger: 'blur' }], // 资质类型: 如CPC，CE，使⽤下⽅cert_type枚举（report_type 为0时必传）
        file_no: [{ required: true, message: '当前⽂件外部唯⼀编号不能为空', trigger: 'blur' }], // 当前⽂件外部唯⼀编号(必传),

        upload_type: [{ required: true, message: '上传⽂件⽅式不能为空', trigger: 'blur' }], // 上传⽂件⽅式： 0：外⽹可下载url转存(必传)
        is_test: [{ required: true, message: '是否为测试数据不能为空', trigger: 'blur' }] // 是否为测试数据 true: 测试数据，false 正式数据 （必传）

        // check_product_name: [{ required: true, message: '检测产品名称不能为空', trigger: 'blur' }] // 检测产品名称(必传)，
        // check_product_model: [{ required: true, message: '检测产品型号不能为空', trigger: 'blur' }], // 检测产品型号(必传),
        // check_product_manufacture: [{ required: true, message: '检测产品⼚商不能为空', trigger: 'blur' }] // 检测产品⼚商(必传),
        // check_date: [{ required: true, message: '报告⽣成⽇期不能为空', trigger: 'blur' }] // 报告⽣成⽇期,格式： yyyy-MM-dd(必传)

      }

    }
  },
  watch: {

  },
  created() {
    // this.getList()
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 关闭弹窗
    handleCancel() {
      // 修改父组件的值
    //   this.showDialog = false
      this.$emit('update:showDialog', false)
      this.$refs.form.resetFields()
      // this.$parent.uploadPage()
    },
    async onSubmit() {
      const result = await this.$refs.form.validate()
      if (result) {
        this.file_json = {
          'data_list': [...this.data_list]
        }

        const res = await uploadFiles(this.file_json)

        // 上传完成后, 把信息保存到数据库中

        this.report_data = {
          ...this.data_list[0], 'is_upload': 1, 'res_json': res.data
        }

        const res1 = await upload_list(this.report_data)
        console.log(res1)
        this.$message.success('上传成功')
        this.handleCancel()

        // 最后关闭

        // this.$emit('update:showDialog', false)
      }
    },
    async getList() {
    //   console.log(this.fileNo)
    //   console.log(this.fileUrl)
    //   console.log(this.reportNo)
      this.data_list[0].file_no = this.fileNo
      this.data_list[0].file_url = this.fileUrl
      const result = await getReprtDetail({ reportno: this.reportNo })

      this.$nextTick(() => {
        this.data_list[0].check_info.check_date = result.check_date

        this.data_list[0].check_info.check_product_name = result.check_product_name
        this.data_list[0].check_info.check_product_model = result.check_product_model
        this.data_list[0].check_info.check_product_manufacture = result.check_product_manufacture
        this.data_list[0].pre_file_no = result.file_no
        this.data_list[0].root_file_no = result.file_no
      })
      setTimeout(() => {

      }, 1000)
    },
    async getManualList() {
    //   console.log(this.fileNo)
    //   console.log(this.fileUrl)
    //   console.log(this.reportNo)
      this.data_list[0].file_no = this.fileNo
      this.data_list[0].file_url = this.fileUrl
      const result = await getManualReprtDetail({ reportno: this.reportNo })

      this.$nextTick(() => {
        this.data_list[0].check_info.check_date = result.check_date

        this.data_list[0].check_info.check_product_name = result.check_product_name
        this.data_list[0].check_info.check_product_model = result.check_product_model
        this.data_list[0].check_info.check_product_manufacture = result.check_product_manufacture
        this.data_list[0].pre_file_no = result.file_no
        this.data_list[0].root_file_no = result.file_no
      })
      setTimeout(() => {

      }, 1000)
    }

  }
}
</script>

