import Cookies from 'js-cookie'

const TokenKey = 'Authorization'// toekn的key
const timeKye = 'hrsaas-timeststamp-key' // 存放token的有效时间

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 获取token的时间戳
export function getTimeStamp() {
  return Cookies.get(timeKye)
}

// 设置token的时间戳
export function setTimeStamp() {
  Cookies.set(timeKye, Date.now())
}
