var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-container" }, [
    _c("div", { staticClass: "dashboard-text" }, [
      _vm._v("name: " + _vm._s(_vm.name))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }